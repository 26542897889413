<template>
    <div>
        <svg v-if="name === 'logo'" viewBox="0 0 50.581421 48.540421">
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                transform="translate(-49.285814,-155.39927)">
                <g id="g2710" transform="translate(17.45599,2.1972575)">
                    <rect style="fill:var(--main);fill-opacity:1;stroke-width:0.264583" id="rect231" width="50.581425"
                        height="30.901279" x="31.829821" y="168.85588" />
                    <g id="g2702" transform="translate(-60.528759,0.79779171)">
                        <path style="fill:var(--main);fill-opacity:1;stroke-width:0.264583"
                            d="m 127.3799,160.15566 -2.4414,-1.89209 3.29589,-0.12207 -1.28174,-4.45555 3.60107,2.92968 2.19725,-4.21141 0.54932,4.27244 4.0283,-2.68553 -1.46484,4.57762 4.15038,0.79345 -3.35692,2.31933 2.01415,1.77001 -2.92968,0.30518 0.36621,3.11278 -2.25829,-1.77002 z"
                            id="path460" />
                        <path style="fill:var(--background);fill-opacity:1;stroke-width:0.264583"
                            d="m 92.703839,165.64122 c 7.250581,0.0863 7.250581,0.0863 7.250581,0.0863 l 2.41686,25.03176 3.02108,-24.08228 h 5.35161 l 3.1074,25.63597 5.00635,-25.72229 6.81899,1.0358 -6.38741,31.93707 -8.97691,-0.0863 -2.07159,-19.4212 -2.93476,18.64435 -8.459011,0.0863 z"
                            id="path563" />
                        <path style="fill:var(--background);fill-opacity:1;stroke-width:0.264583"
                            d="m 127.83463,166.15912 h 6.47373 l 1.46738,34.78552 -6.64637,-0.17263 z" id="path565" />
                        <ellipse style="fill:var(--background);fill-opacity:1;stroke-width:0.264583" id="ellipse567"
                            cx="131.88409" cy="161.02328" rx="4.3589797" ry="4.4452958" />
                    </g>
                </g>
            </g>
        </svg>

        <svg v-if="name === 'tipi'" viewBox="0 0 107.4502 139.6579">
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                transform="translate(-63.162317,-36.930819)">
                <path id="path796" style="stroke:var(--main);stroke-width:1;stroke-opacity:1"
                    d="m 118.48374,36.960814 -1.46658,6.300391 0.13281,0.001 -0.0543,6.545854 -2.93213,-6.688997 0.10801,-0.03772 -3.33778,-5.591907 1.1498,6.354651 0.12454,-0.04341 4.30361,10.269141 h -1.19579 L 63.325346,157.69814 c 0,0 14.130015,12.96773 35.416955,18.73478 l 19.254639,-37.91448 19.2226,37.85092 c 20.06467,-5.87374 33.2326,-19.0407 33.2326,-19.0407 L 119.88365,54.069848 h -0.447 l 4.3217,-10.107394 0.10749,0.03979 1.75079,-6.162415 -3.9212,5.358846 0.12299,0.04548 -2.52698,5.654435 -0.047,-5.623429 0.11576,5.16e-4 z" />
            </g>
        </svg>

        <svg v-if="name === 'porte-ronde'" viewBox="0 0 107.45014 69.438927">
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                transform="translate(-51.924157,-100.48203)">
                <path id="path796-3-8-7" style="stroke:var(--main);stroke-width:1;stroke-opacity:1"
                    d="m 77.381245,100.61432 -25.29406,50.41605 c 0,0 14.130101,12.96776 35.416956,18.73477 l 0.382923,-0.75396 0.293858,-12.60296 0.01465,-0.049 c 0.575872,-14.21707 8.742002,-25.32626 18.628838,-25.34264 9.95669,0.0172 18.15443,11.27914 18.63762,25.60412 l 0.006,-0.0122 0.0327,1.15119 c 0.0108,0.11141 -0.004,0.238 0,0.34882 v 10.64689 l 0.48059,0.9462 c 20.06459,-5.8737 33.2326,-19.0407 33.2326,-19.0407 l -24.50909,-50.04658 z" />
            </g>
        </svg>

        <svg v-if="name === 'porte-triangle'" viewBox="0 0 107.45019 69.438904">
            <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                transform="translate(125.98658,-229.84966)">
                <path id="path796-3" style="stroke:var(--main);stroke-width:1;stroke-opacity:1"
                    d="m -100.5295,229.98196 -25.29406,50.41604 c 0,0 14.13004,12.96773 35.416961,18.73477 l 19.254641,-37.91448 19.2226,37.85092 c 20.064645,-5.87373 33.232585,-19.0407 33.232585,-19.0407 l -24.509085,-50.04655 z" />
            </g>
        </svg>



        <svg v-if="name === 'ruler'" viewBox="0 0 10 66.667">
            <g id="layer1" transform="">
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3387" width="10" height="1.5" x="0"
                    y="0" />
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3387-9" width="10" height="1.5" x="0"
                    y="65.167" />
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3389" width="1.4" height="66.667" x="4.75"
                    y="0" />
            </g>
        </svg>

        <svg v-if="name === 'h-ruler'" viewBox="0 0 66.667 10">
            <g id="layer1">
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3387" width="1.5" height="10" x="0"
                    y="0" />
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3387-9" height="10" width="1.5" x="65.167"
                    y="0" />
                <rect style="fill:var(--main);stroke-width:0.264583" id="rect3389" height="1.5" width="66.667" x="0"
                    y="4.75" />
            </g>
        </svg>

        <svg v-if="name === 'gearbox'" viewBox="0 0 57.007378 57.184624">
            <g transform="translate(-88.232859,-87.907293)">
                <path style="fill:var(--main);stroke-width:0.162449"
                    d="m 115.43947,87.907296 c -1.40663,0 -2.53938,1.132756 -2.53938,2.53938 v 4.834847 a 21.301713,21.171682 0 0 0 -8.21036,3.300573 l -3.14916,-3.124357 c -0.99861,-0.990638 -2.599839,-0.984143 -3.590476,0.01447 l -1.896009,1.910994 c -0.990638,0.998613 -0.984143,2.600358 0.01447,3.590997 l 3.077848,3.05356 a 21.301713,21.171682 0 0 0 -3.512447,8.56847 H 90.77172 c -1.406623,0 -2.538863,1.13224 -2.538863,2.53886 v 2.69234 c 0,1.40663 1.13224,2.53887 2.538863,2.53887 h 5.002796 a 21.301713,21.171682 0 0 0 3.947046,8.60619 l -3.43855,3.46594 c -0.990638,0.99861 -0.984143,2.60036 0.01447,3.59099 l 1.8495,1.83503 c 0.998612,0.99064 2.600358,0.98415 3.590998,-0.0145 l 3.70571,-3.73569 a 21.301713,21.171682 0 0 0 1.51309,0.8475 21.301713,21.171682 44.770313 0 0 6.12676,2.28203 v 5.30924 c 0,1.40662 1.13224,2.53886 2.53886,2.53886 h 2.60553 c 1.40662,0 2.53886,-1.13224 2.53886,-2.53886 v -5.14646 a 21.301713,21.171682 44.770313 0 0 8.58345,-3.44423 l 3.59875,3.5698 c 0.99861,0.99064 2.59984,0.98415 3.59048,-0.0145 l 1.89601,-1.91151 c 0.99064,-0.99861 0.98414,-2.59984 -0.0145,-3.59048 l -3.59255,-3.56412 a 21.301713,21.171682 44.770313 0 0 3.30833,-8.10493 h 4.56406 c 1.40662,0 2.53938,-1.13276 2.53938,-2.53938 v -2.69183 c 0,-1.40662 -1.13276,-2.53938 -2.53938,-2.53938 h -4.60592 a 21.301713,21.171682 44.770313 0 0 -3.28817,-7.95197 l 3.68298,-3.71191 c 0.99064,-0.998616 0.98414,-2.600361 -0.0145,-3.590999 l -2.0345,-2.018481 c -0.99862,-0.990638 -2.60036,-0.984143 -3.591,0.01447 l -3.64525,3.674711 a 21.301713,21.171682 44.770313 0 0 -1.07281,-0.697631 21.301713,21.171682 0 0 0 -7.28741,-2.933155 v -4.914428 c 0,-1.406624 -1.13224,-2.53938 -2.53886,-2.53938 z m 1.49965,14.128334 a 14.477588,14.374811 60.375027 0 1 9.61698,4.21163 14.477588,14.374811 60.375027 0 1 0.14366,20.43855 14.477588,14.374811 60.375027 0 1 -20.36413,0.0196 14.477588,14.374811 60.375027 0 1 -0.14418,-20.43906 14.477588,14.374811 60.375027 0 1 10.74767,-4.23075 z" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ["name"]
}
</script>