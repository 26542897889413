<template>
  <section id="portfolio" class="wrapper">
    <div class="content">
      <div>
        <ul class="tabs">
          <li v-for="item in realisations" :key="'item_name' + item.id">
            <h2
              v-on:click="changeDisplayedRealisation(item.id)"
              :class="{ selected: displayedRealisation === item.id }"
            >
              {{ this.translated[item.id-1] }}
            </h2>
          </li>
        </ul>
      </div>
      <div id="display">
        <div
          class="item"
          v-for="item in realisations"
          :key="'item_' + item.id"
          :class="{ hidden: displayedRealisation !== item.id }"
        >
          <div class="carousel-wrapper">
            <carousel :settings="settings">
              <slide
                v-for="img in item.imgs"
                :key="'item_' + item.id + 'img_' + img"
              >
                <div class="image-wrapper">
                  <img :src="'/img/realisations/' + img" />
                </div>
              </slide>
              <template #addons>
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "RealisationDisplay",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      displayedRealisationBuffer: 1,
      settings: {
        transition: 500,
        itemsToShow: 1,
      },
      realisations: [
        {
          id: 1,
          name: "Film Les Tuches 2",
          imgs: ["les_tuches_1.jpg", "les_tuches_2.jpg", "les_tuches_3.jpg"],
        },
        {
          id: 2,
          name: "Jardin botannique des Premières Nations",
          imgs: ["jardin_b_1.jpg", "jardin_b_2.jpg", "jardin_b_3.jpg"],
        },
        {
          id: 3,
          name: "Parc Oméga",
          imgs: [
            "omega_1.jpg",
            "omega_2.jpg",
            "omega_3.jpg",
            "omega_4.jpg",
          ],
        },
        {
          id: 4,
          name: "Tipis peints",
          imgs: [
            "tipi_peint_1.jpg",
            "tipi_peint_2.jpg",
            "tipi_peint_3.jpg",
            "tipi_peint_4.jpg",
            "tipi_peint_5.jpg",
          ],
        },
        {
          id: 5,
          name: "Yourtes",
          imgs: ["yourte_1.jpg", "yourte_2.jpg"],
        },
        {
          id: 6,
          name: "Divers",
          imgs: [
            "divers_1.jpg",
            "divers_2.jpg",
            "divers_3.jpg",
            "divers_4.jpg",
          ],
        },
      ],
    };
  },
  methods: {
    changeDisplayedRealisation(id) {
      this.displayedRealisationBuffer = id;
    },
  },
  computed: {
    displayedRealisation() {
      return this.displayedRealisationBuffer;
    },
    translated() {
      return [
        this.$t("realisations.tuches"),
        this.$t("realisations.jardin"),
        this.$t("realisations.omega"),
        this.$t("realisations.peints"),
        this.$t("realisations.yourtes"),
        this.$t("realisations.divers"),
      ];
    },
  },
};
</script>

<style>
#portfolio .content {
  height: 80vh;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3.2fr;
}

#portfolio .content > div {
  width: 100%;
  height: 100%;
  position: relative;
}

#portfolio .content ul {
}

#portfolio .content ul.tabs li {
  margin: 10px;
}

#portfolio .content ul.tabs li h2 {
  padding: 10px;
  transition: all 500ms ease-out;
  background-color: var(--background);
  border-radius: 10px;
  /*border: 2px solid var(--main);*/
}

#portfolio .content ul.tabs li h2.selected {
  background-color: var(--main);
  color: rgb(255, 255, 255);
}

#portfolio .content ul.tabs li:hover {
  cursor: pointer;
}

#portfolio .content #display {
}

#portfolio .content #display .item {
  position: absolute;
  pointer-events: all;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 100%;
  transition: opacity 500ms ease-out;
}

#portfolio .content #display .item.hidden {
  opacity: 0;
  pointer-events: none;
}
#portfolio .content #display .item .image-wrapper {
  max-width: 100%;
  max-height: 100%;
}
#portfolio .content #display .item img {
  max-width: 100%;
  max-height: 100%;
}

.carousel {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  position: relative;
}
.carousel-wrapper,
.carousel,
.carousel__slide,
.carousel__track,
.carousel__viewport {
  height: 100%;
}

.carousel .carousel__slide > div {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.carousel .carousel__slide img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.carousel .carousel__pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 30px;
}

.carousel .carousel__pagination-button {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--main);
  opacity: 50%;
  transition: all 250ms ease-out;
}

.carousel .carousel__pagination-button--active {
  opacity: 100%;
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 1200px) {
  #portfolio .content {
    height: fit-content;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
  }
  #portfolio .content #display {
    max-height: 80vh;
  }
}
@media screen and (max-width: 740px) {
  #portfolio .content {
    grid-template-rows: 1fr 1fr;
  }
}
</style>
