import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SubmissionView from "../views/SubmissionView.vue";

const routes = [
  {
    path: "/",
    name: "under-construction",
    redirect: "accueil"
  },
  {
    path: "/accueil/",
    name: "accueil",
    component: HomeView,
    props: true,
  },
  {
    path: "/soumission/",
    name: "soumission",
    component: SubmissionView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      if (to.path !== "/soumission") {
        return {
          el: to.hash,
          behavior: "smooth",
          top: 85,
        };
      } else {
        return {
          el: to.hash,
          behavior: "smooth",
          top: 215,
        };
      }
    } else {
      return { top: 0 };
    }
  },
});

export default router;
