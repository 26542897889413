<template>
  <nav-bar
    :active="navBarActive"
    :showLinks="navBarShowLinks"
    @switchState="navBarActive = !navBarActive"
  />
  <router-view />
  <custom-footer />
</template>

<script>
import NavBarVue from "./components/utils/NavBar.vue";
import CustomFooter from "./components/utils/CustomFooter.vue";

export default {
  name: "App",
  components: {
    "nav-bar": NavBarVue,
    "custom-footer": CustomFooter,
  },
  data() {
    return {
      dark_theme: {
        main_c: "#A72F1E",
        secondary_c: "",
        text_c: "#7DAAB4",
        background_c: "#F4EFEE",
      },
      main_theme: {
        main_c: "#C8EB73",
        secondary_c: "",
        text_c: "#5b200b",
        background_c: "#F4EFEE",
      },
      current_theme: "main",
      navBarActive: false,
      navBarShowLinks: false,
    };
  },
  methods: {
    changeStyle(theme) {
      document.querySelector("#app").classList = [theme];
    },
  },
  watch: {
    $route(to) {
      this.navBarActive = false;
      if (to.name === undefined || to.name === "under-construction") {
        this.navBarShowLinks = false;
      } else {
        this.navBarShowLinks = true;
      }
      if (to.name === "aile-du-vent") {
        this.changeStyle("dark");
      } else {
        this.changeStyle("main");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Dosis&family=Arvo&family=Ms+Madi&family=Caveat&family=Poppins:wght@400&display=swap");

#app {
  background-color: white;
  min-height: 100vh;
  --header-height: 95px;
  padding-top: var(--header-height);
  position: relative;
  color: var(--text);
  display: flex;
  flex-direction: column;
}

footer {
  height: 400px;
  margin-top: auto;
  background-color: var(--main);
}

svg {
  height: 100%;
  width: 100%;
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
}

.wrapper {
  width: calc(100% - 300px);
  max-width: 1440px;
  margin: auto;
}

a:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
}

#app section {
  margin-top: 100px;
}

#app .page {
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
}

#app.main {
  --background: #f4efee;
  --background-header: #f4efee;
  --main: #a72f1e;
  --text: #5b200b;
}

#app.dark {
  --background: #c8eb73;
  --background-header: #d4ef8d;
  --background-trans: #c9eb738f;
  --text: #323232;
  --main: #323232;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Dosis", serif;
}

*::-webkit-scrollbar {
  background-color: white;
  width: 10px;
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: #a72f1e;
  border-radius: 9999px;
}

.dosis-font {
  font-family: "Dosis", sans-serif;
  font-variant: small-caps;
}

.cursive-font {
  font-family: "Ms Madi", cursive;
}

.caveat-font {
  font-family: "Caveat", cursive;
}

.arvo-font {
  font-family: "Arvo", serif;
  font-variant: small-caps;
}

h1 {
  font-size: 62px;
  text-align: center;
  margin: 80px auto;
}

h2 {
  font-size: 32px;
}

h2.title {
  margin-bottom: 15px;
}

p {
  font-size: 20px;
}

/*BTNS*/
.text-link {
  color: var(--main);
  text-decoration: underline;
  line-height: 22px;
  font-size: 18px;
}

.text-link:hover {
  cursor: pointer;
}

.confirm-btn {
  border-radius: 10px;
  background-color: white;
  padding: 10px 15px;
  border: 2px solid var(--main);
  font-size: 20px;
  color: var(--main);
  transition: all 250ms ease-out;
  text-align: center;
}

.confirm-btn:hover {
  cursor: pointer;
  background-color: var(--main);
  color: var(--background);
  border: 2px solid var(--background);
}

/*FORMS*/
.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  border-radius: 20px;
  background-color: white;
}

/*
.popup .form {
  border: 2px solid var(--main);
  padding: 100px;
  max-width: 1200px;
}*/

.form .row {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.form textarea,
.form input {
  font-size: 18px;
  padding: 10px 15px;
  border: 2px solid var(--background);
  border-radius: 10px;
  width: 100%;
  background-color: white;
  color: var(--main);
}
.form textarea.required,
.form input.required {
  background-color: rgb(255, 200, 200);
}

.form textarea {
  resize: none;
}

/*
.form .close {
  position: absolute;
  top: 25px;
  right: 25px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--background);
  border: 2px solid var(--main);
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form .close svg {
  height: 75%;
  width: 75%;
  fill: var(--main);
}

.form .close:hover {
  cursor: pointer;
  background-color: var(--main);
}

.form .close:hover svg {
  fill: var(--background);
}*/

/*POPUPS*/
.popup {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 102;
  background-color: var(--background);
  padding: 75px;
  overflow: scroll;
  transition: opacity 500ms ease-out;
  pointer-events: none;
}

.popup.active {
  opacity: 100%;
  pointer-events: all;
}

.between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    width: calc(100% - 100px);
    max-width: unset;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    width: calc(100% - 80px);
  }
  #app section {
    margin-top: 50px;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    width: calc(100% - 40px);
  }
}
</style>
