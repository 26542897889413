<template>
  <section id="contact-form">
    <div v-if="reponseMessage === ''" class="form">
      <div id="inputs">
        <div>
          <h3 class="full">{{ $t("form.prenom") + "*" }}</h3>
          <input :class="{ required: required.name }" v-model="userName" />
          <h3 class="full">{{ $t("form.nom") + "*" }}</h3>
          <input
            :class="{ required: required.lastName }"
            v-model="userLastName"
          />
          <h3 class="full">{{ $t("form.email") + "*" }}</h3>
          <input :class="{ required: required.email }" v-model="userEmail" />
          <h3 class="full">{{ $t("form.telephone") }}</h3>
          <input v-model="userTelephone" />
        </div>
        <div class="question">
          <h3>{{ $t("form.commentaire") + "*" }}</h3>
          <textarea
            :class="{ required: required.comment }"
            rows="8"
            class="full"
            v-model="userComment"
          ></textarea>
        </div>
      </div>
      <div id="information">
        <div id="location">
          <!--<div class="image-wrapper"><img src="@/assets/maps_snap.png" /></div>--->
          <h4>(418) 289-3841</h4>
          <h4>tipiwitente@gmail.com</h4>
          <h4>Sainte-Thècle, QC</h4>
        </div>
        <div>
          <a
            href="https://www.google.com/maps/place/Sainte-Th%C3%A8cle/@46.5472735,-72.3706381,9.25z"
            target="_blank"
          >
            <div class="confirm-btn">{{ $t("contact.google_maps") }}</div>
          </a>
          <button @click="contactFormSubmit()" class="confirm-btn">
            {{ $t("form.soumettre") }}
          </button>
        </div>
      </div>
    </div>
    <div class="form-message" v-else>
      <h2>{{ reponseMessage }}</h2>
    </div>
  </section>
</template>
<script>
import { contactFormSubmission } from "@/requests/submission.js";
export default {
  name: "ContactForm",
  data() {
    return {
      userName: "",
      userLastName: "",
      userEmail: "",
      userTelephone: "",
      userComment: "",
      required: {
        name: false,
        lastName: false,
        email: false,
        comment: false,
      },
      reponseMessage: "",
    };
  },
  methods: {
    async contactFormSubmit() {
      let completed = true;
      if (this.userName === "") {
        completed = false;
        this.required.name = true;
      } else {
        this.required.name = false;
      }
      if (this.userLastName === "") {
        completed = false;
        this.required.lastName = true;
      } else {
        this.required.lastName = false;
      }
      if (this.userEmail === "") {
        completed = false;
        this.required.email = true;
      } else {
        const validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!this.userEmail.match(validRegex)) {
          completed = false;
          this.required.email = true;
        } else {
          this.required.email = false;
        }
      }
      if (this.userComment === "") {
        completed = false;
        this.required.comment = true;
      } else {
        this.required.comment = false;
      }
      if (completed) {
        const resp = await contactFormSubmission({
          name: this.userName,
          last_name: this.userLastName,
          email: this.userEmail,
          telephone: this.userTelephone,
          comment: this.userComment,
        });
        if (resp.success) {
          this.reponseMessage = this.$t("form.message_reponse.succes");
        } else {
          this.reponseMessage = this.$t("form.message_reponse.echec");
        }
      }
    },
  },
};
</script>
<style>
#contact-form .form-message {
  display: flex;
  flex-direction:column;
  align-items: center;
  min-height: 25vh;
}
#contact-form .form-message h2 {
  width: fit-content;
}
#contact-form .form {
  background-color: var(--background);
  width: 60%;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

#contact-form .form > div {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  padding: 20px;
}

#contact-form .form a {
  width: 100%;
}

#contact-form .form .confirm-btn {
  width: 100%;
  margin-top: 20px;
}

#contact-form .form #inputs {
  width: 100%;
}
#contact-form .form #inputs > div {
}
#contact-form .form #inputs .question {
  display: grid;
  grid-template-rows: 38px auto;
}

#contact-form .form #inputs textarea {
  height: 100%;
}

#contact-form .form h4 {
  color: var(--main);
  margin: 10px 0 5px 0;
  font-size: 26px;
}

#contact-form .form #inputs h3 {
  color: var(--main);
  margin: 10px 0 5px 0;
}

#contact-form .form #information {
}

#contact-form .form #information #location {
}

#contact-form .form #information .image-wrapper {
  height: 80%;
  width: 100%;
}

#contact-form .form #information img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

@media screen and (max-width: 1440px) {
  #contact-form .form {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  #contact-form .form {
    width: 95%;
  }
}
@media screen and (max-width: 767px) {
  #contact-form .form {
    padding: 10px;
  }
  #contact-form .form > div {
    grid-template-columns: 1fr;
    padding: 10px;
  }
}
</style>
