import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from "vue3-i18n"
import fr_file from "./lang/fr.json"
import en_file from "./lang/en.json"

const content = {
    fr: fr_file.fr,
    en: en_file.en
}

const i18n = createI18n({
    locale: "fr",
    messages: content
})


createApp(App).use(router).use(i18n).mount('#app')
