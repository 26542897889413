<template>
  <div class="section-header" :id="data.ref">
    <div class="bg"><img :src="this.data.bgImg" /></div>
    <div class="content">
      <h1>{{ title }}</h1>
      <p>{{ desc }}</p>
      <router-link v-for="link in links" :key="link.to" :to="link.to"
        ><div class="confirm-btn">{{ link.text }}</div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  data() {
    return {};
  },
  computed: {},
  props: ["data", "title", "desc", "links"],
};
</script>

<style>
#home-view .section-header {
  height: 75vh;
  position: relative;
  margin-top: 50px;
}

#home-view .section-header .confirm-btn {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border-color: white;
  color: white;
}
#home-view .section-header .confirm-btn:hover {
  border-color: var(--main);
  background-color: var(--main);
  color: white;
}
#home-view #nos-tipis.section-header {
  margin-top: 0;
}

#home-view .section-header .bg {
  height: 100%;
  width: 100%;
  background-color: var(--main);
  position: absolute;
  z-index: 10;
}

#home-view .section-header .content {
  z-index: 20;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#home-view .section-header .content h1 {
  color: white;
  width: fit-content;
  font-weight: bold;
  font-variant: small-caps;
  font-size: 128px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 10px;
}

#home-view .section-header .content p {
  color: white;
  font-size: 26px;
  width: 80%;
  text-align: center;
}

#home-view .section-header .bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  #home-view .section-header .content h1 {
    font-size: 72px;
    line-height: 76px;
  }
}

@media screen and (max-width: 767px) {
  #home-view .section-header .content p {
    font-size: 22px;
  }
  #home-view .section-header {
    height: calc(100vh - var(--header-height));
  }
}
</style>
