<template>
  <div id="home-view" class="page">
    <div class="main-desc">
      <div class="background-header">
        <img src="@/assets/accueil.jpg" />
      </div>
      <div class="txt">
        <p>
          {{ $t("main_desc") }}
        </p>
      </div>
    </div>
    <section-header
      :links="linksTipi"
      :data="sectionHeaders.tipi"
      :title="$t('accueil.tipi.titre')"
      :desc="$t('accueil.tipi.desc')"
    />
    <tipi-info />
    <section-header
      :links="[]"
      :data="sectionHeaders.portfolio"
      :title="$t('accueil.realisations.titre')"
      :desc="$t('accueil.realisations.desc')"
    />
    <realisations />
    <section-header
      :links="[]"
      :data="sectionHeaders.team"
      :title="$t('accueil.equipe.titre')"
      :desc="$t('accueil.equipe.desc')"
    />
    <team-display />
    <section-header
      :links="[]"
      :data="sectionHeaders.contact"
      :title="$t('accueil.contact.titre')"
      :desc="$t('accueil.contact.desc')"
    />
    <contact-form />
  </div>
</template>

<script>
import RealisationDisplay from "@/components/home/RealisationDisplay.vue";
import TipiInfo from "@/components/home/TipiInfo.vue";
import TeamDisplay from "@/components/home/TeamDisplay.vue";
import SectionHeader from "@/components/home/SectionHeader.vue";
import ContactForm from "@/components/home/ContactForm.vue";

export default {
  name: "HomeView",
  components: {
    realisations: RealisationDisplay,
    "tipi-info": TipiInfo,
    "team-display": TeamDisplay,
    "section-header": SectionHeader,
    "contact-form": ContactForm,
  },
  props: { section: { default: "top" } },
  data() {
    return {
      sectionHeaders: {
        tipi: {
          ref: "nos-tipis",
          bgImg: "/img/entete_tipi.jpg",
        },
        portfolio: {
          ref: "nos-realisations",
          bgImg: "/img/arc_en_ciel_tipi.jpg",
        },
        team: {
          ref: "notre-equipe",
          bgImg: "/img/entete_equipe.jpg",
        },
        contact: {
          ref: "nous-contacter",
          bgImg: "/img/entete_contact.jpg",
        },
      },
    };
  },
  computed: {
    linksTipi() {
      return [{text:this.$t("accueil.tipi.links.soumission"), to:"/soumission"}];
    },
  },
};
</script>

<style>
#home-view .background-header {
  position: relative;
  width: 100%;
}

#home-view .background-header img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-view .main-desc {
  display: grid;
  grid-template-rows: 5fr 1fr;
  height: calc(100vh - var(--header-height));
  width: 100%;
  position: relative;
}

#home-view .main-desc .txt {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#home-view .main-desc p {
  font-size: 32px;
  width: fit-content;
  text-align: center;
  font-weight: bold;
  font-style: italic;
}

@media screen and (max-width: 767px) {
  #home-view .main-desc .txt {
    position: absolute;
    bottom: 25px;
    height: fit-content;
    color: white;
  }
  #home-view .main-desc p {
    width: 50%;
    margin: auto;
    font-size: 26px;
    text-shadow: #000 5px 2px 15px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  #home-view .main-desc {
    grid-template-rows: 1fr;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 600px) {
  #home-view .main-desc p {
    width: 80%;
  }
}
</style>
