<template>
  <section id="submission-view">
    <div class="wrapper">
      <div v-if="responseMessage === ''" class="form">
        <h2>{{ $t("soumission.diametre.titre") }}</h2>
        <div class="row size">
          <div
            v-for="(item, index) in tipisData.sizes"
            :key="index"
            class="box"
            :class="{ selected: selectedSize === index }"
            v-on:click="changeSelectedSize(index)"
          >
            <div>
              <div class="size-choice">
                <svg-element :name="'tipi'" />
                <div class="horizontal">
                  <svg-element :name="'h-ruler'" />
                </div>
                <h5>{{ item.dimension }}</h5>
                <h5>{{ item.lits }}</h5>
              </div>
              <h3 class="price-tag richard-hidden">{{ item.price + "$" }}</h3>
            </div>
          </div>
        </div>
        <div class="richard-hidden">
          <h2 class="full">{{ $t("soumission.porte.titre") }}</h2>
        </div>
        <div class="row">
          <div class="doors richard-hidden">
            <div class="choice">
              <div
                class="box"
                :class="{ selected: selectedDoor === 'straight' }"
                v-on:click="changeSelectedDoor('straight')"
              >
                <div>
                  <div class="door-choice">
                    <svg-element :name="'porte-triangle'" />
                  </div>
                </div>
              </div>
              <div
                class="box"
                :class="{ selected: selectedDoor === 'round' }"
                v-on:click="changeSelectedDoor('round')"
              >
                <div>
                  <div class="door-choice">
                    <svg-element :name="'porte-ronde'" />
                  </div>
                  <h3 class="price-tag richard-hidden">
                    {{ "+" + tipisData.sizes[selectedSize].door + "$" }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="radios">
            <div>
              <div class="row full">
                <div
                  class="radio"
                  :class="{ active: selectedLiner === true }"
                  @click="selectedLiner = !selectedLiner"
                >
                  <div></div>
                </div>
                <div class="between">
                  <h2>{{ $t("soumission.radios.liner") }}</h2>
                  <div class="richard-hidden">
                  <h2 v-if="selectedSize != 0">{{ currentLinerPrice }}</h2>
                  <h2 v-else>ND</h2>
                </div>
                </div>
              </div>

              <div class="full">
                <p>{{ $t("soumission.radios.d_liner") }}</p>
              </div>
            </div>
            <div>
              <div
                class="radio"
                :class="{ active: selectedPerches === true }"
                @click="selectedPerches = !selectedPerches"
              >
                <div></div>
              </div>
              <h2>{{ $t("soumission.radios.perches") }}</h2>
              <div class="full">
                <p>{{ $t("soumission.radios.d_perches") }}</p>
              </div>
            </div>
            <div>
              <div
                class="radio"
                :class="{ active: selectedInstallation === true }"
                @click="selectedInstallation = !selectedInstallation"
              >
                <div></div>
              </div>
              <h2>{{ $t("soumission.radios.installation") }}</h2>
              <div class="full">
                <p>{{ $t("soumission.radios.d_installation") }}</p>
              </div>
            </div>
            <div>
              <div
                class="radio"
                :class="{
                  active:
                    selectedLivraison === true || selectedInstallation === true,
                }"
                @click="selectedLivraison = !selectedLivraison"
              >
                <div></div>
              </div>
              <h2>{{ $t("soumission.radios.livraison") }}</h2>
              <div class="full">
                <p>{{ $t("soumission.radios.d_livraison") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row full">
          <div>
            <h2>{{ $t("soumission.materiel_inclus.titre") }}</h2>
            <div class="table">
              <div class="item">
                <div class="bullet"></div>
                <h4>{{ $t("soumission.materiel_inclus.contenu.goujons") }}</h4>
              </div>
              <div class="item">
                <div class="bullet"></div>
                <h4>{{ $t("soumission.materiel_inclus.contenu.sac") }}</h4>
              </div>
              <div class="item">
                <div class="bullet"></div>
                <h4>{{ $t("soumission.materiel_inclus.contenu.cordes_1") }}</h4>
              </div>
              <div class="item">
                <div class="bullet"></div>
                <h4>{{ $t("soumission.materiel_inclus.contenu.cordes_2") }}</h4>
              </div>
              <div class="item">
                <div class="bullet"></div>
                <h4>{{ $t("soumission.materiel_inclus.contenu.cordes_3") }}</h4>
              </div>
              <div class="item">
                <div class="bullet"></div>
                <h4>
                  {{ $t("soumission.materiel_inclus.contenu.instruction") }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div id="user-info" class="row">
          <div class="full">
            <h3 class="full">{{ $t("form.prenom") + " *" }}</h3>
            <input :class="{ required: required.name }" v-model="userName" />
          </div>
          <div class="full">
            <h3 class="full">{{ $t("form.nom") + " *" }}</h3>
            <input
              :class="{ required: required.lastName }"
              v-model="userLastName"
            />
          </div>
        </div>
        <div class="row">
          <div class="full">
            <h3 class="full">{{ $t("form.email") + " *" }}</h3>
            <input :class="{ required: required.email }" v-model="userEmail" />
          </div>
          <div class="full">
            <h3 class="full">{{ $t("form.telephone") }}</h3>
            <input v-model="userTelephone" />
          </div>
        </div>
        <div class="row">
          <div class="full">
            <h3 class="full">{{ $t("form.adresse") }}</h3>
            <input v-model="userAddress" />
          </div>
          <div class="full">
            <h3 class="full">{{ $t("form.ville") }}</h3>
            <input v-model="userCity" />
          </div>
        </div>
        <div class="row">
          <div class="full">
            <h3>{{ $t("form.commentaire") }}</h3>
            <textarea rows="3" class="full" v-model="userComment"></textarea>
          </div>
        </div>
        <button class="confirm-btn" v-on:click="submitForm()">
          {{ $t("form.soumettre") }}
        </button>
      </div>
      <div class="submission-r" v-else>
        <h2>{{ responseMessage }}</h2>
      </div>
    </div>
    <div class="richard-hidden" v-if="responseMessage === ''" id="total-price">
      <h2>{{ "Total: " + totalPrice + "$" }}</h2>
      <h5 v-if="selectedPerches">
        {{ $t("soumission.prix_indefinis.perches") }}
      </h5>
      <h5 v-if="selectedInstallation">
        {{ $t("soumission.prix_indefinis.installation") }}
      </h5>
      <h5 v-else-if="selectedLivraison">
        {{ $t("soumission.prix_indefinis.livraison") }}
      </h5>
    </div>
  </section>
</template>

<script>
import SvgElementVue from "@/components/utils/SvgElement.vue";
import { tipiSubmission } from "@/requests/submission.js";
import { useI18n } from "vue3-i18n";

export default {
  name: "SubmissionForm",
  components: {
    "svg-element": SvgElementVue,
  },
  computed: {
    tipisData() {
      return {
        sizes: [
          {
            lits: "1-4 " + this.$t("soumission.diametre.contenu.enfants"),
            price: 650,
            door: 95,
            liner: "ND",
            dimension: "9"+ this.$t("soumission.diametre.contenu.pieds") + " / 2.74m",
            perches: 1000,
          },
          {
            lits: "1-2 " + this.$t("soumission.diametre.contenu.adultes"),
            price: 825,
            door: 125,
            liner: 425,
            dimension: "12"+ this.$t("soumission.diametre.contenu.pieds") + " / 3.664m",
            perches: 1100,
          },
          {
            lits: "3-4 " + this.$t("soumission.diametre.contenu.adultes"),
            price: 1185,
            door: 125,
            liner: 485,
            dimension: "15"+ this.$t("soumission.diametre.contenu.pieds") + " / 4.57m",
            perches: 1200,
          },
          {
            lits: "4-7 " + this.$t("soumission.diametre.contenu.adultes"),
            price: 1635,
            door: 125,
            liner: 550,
            dimension: "18"+ this.$t("soumission.diametre.contenu.pieds") + " / 5.49m",
            perches: 1300,
          },
          {
            lits: "6-9 " + this.$t("soumission.diametre.contenu.adultes"),
            price: 1875,
            door: 125,
            liner: 625,
            dimension: "20"+ this.$t("soumission.diametre.contenu.pieds") + " / 6.10m",
            perches: 1400,
          },
          {
            lits: "11-13 " + this.$t("soumission.diametre.contenu.adultes"),
            price: 2775,
            door: 125,
            liner: 825,
            dimension: "24"+ this.$t("soumission.diametre.contenu.pieds") + " / 7.32m",
            perches: 1500,
          },
        ],
      };
    },
    currentLinerPrice() {
      let s = this.tipisData.sizes[this.selectedSize].liner;
      if (s !== "ND") {
        s = "+" + s + "$";
      }
      return s;
    },

    totalPrice() {
      const data = this.tipisData.sizes[this.selectedSize];
      let p = data.price;
      if (this.selectedDoor === "round") {
        p += data.door;
      }
      if (this.selectedLiner && data.liner !== "ND") {
        p += data.liner;
      }
      return p;
    },
  },
  data() {
    return {
      selectedSize: 0,
      selectedDoor: "straight",
      selectedLiner: false,
      selectedInstallation: false,
      selectedLivraison: false,
      selectedPerches: false,
      popupActive: false,
      userName: "",
      userLastName: "",
      userEmail: "",
      userTelephone: "",
      userAddress: "",
      userCity: "",
      userComment: "",
      required: {
        name: false,
        lastName: false,
        email: false,
      },
      responseMessage: "",
    };
  },
  methods: {
    changeSelectedSize(x) {
      this.selectedSize = x;
    },

    changeSelectedDoor(x) {
      this.selectedDoor = x;
    },

    async submitForm() {
      let price = this.totalPrice + "$";
      if (this.selectedPerches) {
        price += " " + this.$t("soumission.prix_indefinis.perches");
      }
      if (this.selectedInstallation) {
        price += " " + this.$t("soumission.prix_indefinis.installation");
      } else if (this.selectedLivraison) {
        price += " " + this.$t("soumission.prix_indefinis.livraison");
      }
      let completed = true;
      if (this.userName === "") {
        completed = false;
        this.required.name = true;
      }
      if (this.userLastName === "") {
        completed = false;
        this.required.lastName = true;
      }
      if (this.userEmail === "") {
        completed = false;
        this.required.email = true;
      } else {
        const validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!this.userEmail.match(validRegex)) {
          completed = false;
          this.required.email = true;
        }
      }
      const size = this.tipisData.sizes[this.selectedSize].dimension;
      console.log(this.i18n.getLocale());
      if (completed) {
        const resp = await tipiSubmission({
          price: price,
          tipi: {
            size: size,
            door: this.selectedDoor,
            perches: this.selectedPerches,
            livraison: this.selectedLivraison,
            installation: this.selectedInstallation,
            liner: this.selectedLiner,
          },
          user: {
            name: this.userName,
            last_name: this.userLastName,
            email: this.userEmail,
            telephone: this.userTelephone,
            address: this.userAddress + ", " + this.userCity,
            comment: this.userComment,
          },
        });
        if (resp.success) {
          this.responseMessage = this.$t("soumission.message_reponse.succes");
        } else {
          this.responseMessage = this.$t("soumission.message_reponse.echec");
        }
      } else {
        this.$router.push({ path: "/soumission", hash: "#user-info" });
      }
    },
  },
  mounted() {
    this.i18n = useI18n();
  },
};
</script>

<style>
.richard-hidden {
  display: none;
}
#submission-view .submission-r {
  height: calc(100vh - var(--header-height) - 100px);
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#submission-view .form .box {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 10px;
  border: 2px solid var(--background);
  background-color: white;
}

#submission-view .form.hidden {
  display: none;
}

#submission-view .form .box.disabled {
  pointer-events: none;
}

#submission-view .form {
  margin-bottom: 100px;
  padding: 60px 80px;
  background-color: var(--main);
  border: 2px solid var(--background);
}

#submission-view #total-price {
  position: fixed;
  top: calc(25px + var(--header-height));
  opacity: 0;
  background-color: var(--background);
  z-index: 80;
  left: 50%;
  padding: 20px 30px;
  border: 2px solid var(--background);
  border-radius: 10px;
  transition: opacity 500ms ease-out;
  transform: translateX(-50%);
}

#submission-view #total-price * {
  text-align: center;
  color: var(--main);
}

#submission-view #total-price {
  opacity: 100%;
}

#submission-view .form .box .price-tag {
  position: absolute;
  bottom: 25px;
  right: 25px;
  font-size: 26px;
}

#submission-view .form svg path {
  transition: all 250ms ease-out;
}

#submission-view .form .full {
  width: 100%;
}

#submission-view .form .box:hover {
  cursor: pointer;
}

#submission-view .form .box > div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}

#submission-view .form .row .choice {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

#submission-view .form .box .size-choice {
  left: calc(50%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

#submission-view .form .box .size-choice h5 {
  font-size: 18px;
  overflow: visible;
  white-space: nowrap;
}

#submission-view .form .box .door-choice {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

#submission-view .form .confirm-btn {
  margin-top: 25px;
}

#submission-view .confirm-btn.fixed {
  position: fixed;
  top: 150px;
  right: 50px;
}

#submission-view .form .box h6 {
  color: var(--main);
}

#submission-view .form .confirm-btn {
  padding: 15px 80px;
  width: fit-content;
  margin: auto;
  margin-top: 20px;
}

#submission-view .form h5,
#submission-view .form h4,
#submission-view .form h3,
#submission-view .form h2 {
  color: var(--background);
}

#submission-view .form .box h5,
#submission-view .form .box h4,
#submission-view .form .box h3,
#submission-view .form .box h2 {
  color: var(--main);
}

#submission-view .form h4,
#submission-view .form p {
  font-size: 22px;
  color: var(--background);
  font-weight: 500;
}

#submission-view .form h2 {
  margin-top: 20px;
}

#submission-view .form .box h4 {
  margin-top: 5px;
}

#submission-view .form .box .size-choice .horizontal {
  width: 100%;
  margin: 0 auto;
}

#submission-view .form .box .door-choice svg path,
#submission-view .form .box .size-choice svg path {
  fill: var(--background);
}

#submission-view .form .box.selected .door-choice svg path,
#submission-view .form .box.selected .size-choice svg path {
  fill: var(--main);
}

#submission-view .form .box:nth-child(1) > div .size-choice {
  width: 25%;
}

#submission-view .form .box:nth-child(2) > div .size-choice {
  width: 28%;
}

#submission-view .form .box:nth-child(3) > div .size-choice {
  width: 31%;
}

#submission-view .form .box:nth-child(4) > div .size-choice {
  width: 34%;
}

#submission-view .form .box:nth-child(5) > div .size-choice {
  width: 37%;
}

#submission-view .form .box:nth-child(6) > div .size-choice {
  width: 40%;
}

#submission-view .form .box .size-choice svg:nth-child(1) {
  width: 100%;
}

#submission-view .form .row.size {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

#submission-view .form .row.grid {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  column-gap: 50px;
}

#submission-view .form .row .full h3 {
  margin-bottom: 10px;
}

#submission-view .form .doors {
  width: 100%;
}

#submission-view .form .doors .choice {
  grid-template-columns: 1fr;
  row-gap: 40px;
}

#submission-view .form .radios > div {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

#submission-view .form .radios h2 {
  color: var(--background);
}

#submission-view .form .radios {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  margin-top: 30px;
  gap: 30px;
  border-left: 2px solid var(--background);
}

#submission-view .form .radios div.radio {
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  border: 2px solid var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

#submission-view .form .radios div.radio > div {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

#submission-view .form .radios div.radio.active > div {
  background-color: var(--main);
}

#submission-view .form .radios div.radio:hover {
  background-color: var(--background);
  cursor: pointer;
}

#submission-view .form .table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  column-gap: 20px;
  margin: 15px 0 25px;
}

#submission-view .form .table .item {
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

#submission-view .form .radios .row.full {
  align-items: center;
  gap: 15px;
}

#submission-view .form .radios h2 {
  margin: 0;
}

#submission-view .form .table .bullet {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: -20px;
  background-color: var(--background);
}

@media screen and (max-width: 1440px) {
  #submission-view .form {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  #submission-view .form .row.grid {
    grid-template-columns: 1fr;
  }

  #submission-view .form .doors .choice {
    grid-template-columns: 1fr 1fr 0.5fr;
  }

  #submission-view .form .radios {
    gap: 40px;
  }
  #submission-view .form .row.size {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1024px) {
  #submission-view .form {
    width: 100%;
  }
  #submission-view .form .doors .choice,
  .popup {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 767px) {
  #app section#submission-view {
    margin: 0;
  }
  #submission-view .form {
    padding: 25px;
    gap: 40px;
    margin-top: 100px;
  }

  #submission-view .form .row {
    flex-direction: column;
    gap: 40px;
  }

  #submission-view .form .radios .row.full {
    flex-direction: row;
  }

  #submission-view .form .table {
    grid-template-columns: 1fr;
  }
  #submission-view #total-price {
    width: 80%;
  }
  #submission-view .form .radios {
    border-left: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 500px) {
  #submission-view .form .doors .choice,
  #submission-view .form .row.size {
    grid-template-columns: 1fr;
  }
}
</style>
