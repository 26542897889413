<template>
  <section id="tipi-info" class="wrapper">
    <div class="informations">
      <div v-for="item in content.informations" :key="item.titre">
        <div class="image-wrapper"><img :src="'/img/' + item.img" /></div>
        <div class="text">
          <h2>{{ item.title }}</h2>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TipiInfo',
  computed: {
    content() {
      return {
        informations: [
          {
            img: "toile.jpg",
            title: this.$t("tipi.toile.titre"),
            text: this.$t("tipi.toile.desc"),
          },
          {
            img: "liner.jpg",
            title: this.$t("tipi.liner.titre"),
            text: this.$t("tipi.liner.desc"),
          },
          {
            img: "perches.jpg",
            title: this.$t("tipi.perches.titre"),
            text: this.$t("tipi.perches.desc"),
          },
          {
            img: "installation.jpg",
            title: this.$t("tipi.installation.titre"),
            text: this.$t("tipi.installation.desc"),
          }
        ],
      }
    }
  }
}
</script>

<style>
#tipi-info .informations .text {
  margin-left: 250px;
}

#tipi-info .informations .image-wrapper {
  border: 2px solid var(--main);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.75);
}

#tipi-info .informations p {
  margin-top: 20px;
  height: 150px;
  overflow-y: auto;
  padding-right: 50px;
  white-space: pre-line;
}

#tipi-info .informations {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 80%;
  margin: auto;
}

#tipi-info .informations>div {
  border-radius: 9999px;
  padding: 50px 100px;
  background-color: var(--background);
  position: relative;
}


/*VIEW*/
#tipi-info {
  display: flex;
  flex-direction: column;
}

#tipi-info .confirm-btn {
  margin: auto;
  display: block;
  margin: 40px auto;
}

#tipi-info .paragraph {
  text-align: justify;
  position: relative;
  width: 100%;
  background-color: var(--background);
  padding: 50px;
  padding-bottom: 100px;
  border-radius: 15px;
}

#tipi-info .paragraph .image-wrapper {
  position: absolute;
  height: 100%;
  width: 40%;
  top: 0;
}

#tipi-info .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#tipi-info .paragraph {
  padding-left: 45%;
}

#tipi-info .text h2 {
  font-variant: small-caps;
  font-weight: bolder;
}

#tipi-info .paragraph .image-wrapper {
  left: 0;
}

#tipi-info .paragraph h1 {
  text-align: right;
}

@media screen and (max-width: 1440px) {
  #tipi-info .informations {
    max-width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  #tipi-info .informations {
    margin-top: 200px;
  }

  #tipi-info .informations p {
    height: 180px;
  }

  #tipi-info .informations .image-wrapper {
    top: 0;
    left: 50%;
    transform: translate(-50%, -65%);
    height: 350px;
  }

  #tipi-info .informations>div {
    margin: 125px 0;
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 25px;
  }

  #tipi-info .informations .text {
    margin-left: 0;
  }

  #tipi-info .informations .text p {
    height: fit-content;
    padding-right: 0;
  }
}

@media screen and (max-width: 900px) {
  #tipi-info .informations {
    margin-top: 150px;
    max-width: 100%;
  }

  #tipi-info .informations .image-wrapper {
    height: 300px;
  }

  #tipi-info .informations>div {
    margin: 100px 0;
    padding: 60px;
    padding-top: 150px;
    padding-bottom: 75px;
  }

  #tipi-info .informations .text p,
  #tipi-info .informations .text h2 {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  #tipi-info .informations {
    margin-top: 100px;
  }
}

@media screen and (max-width: 600px) {

  #tipi-info .informations .image-wrapper {
    height: 250px;
  }

  #tipi-info .informations>div {
    margin: 100px 0;
    padding: 30px;
    padding-top: 125px;
    padding-bottom: 75px;
  }
}
</style>
