import axios from "axios";
const BASE_URL = "https://www.witente.com/api/"

export async function tipiSubmission(data) {
  return await axios
    .post(BASE_URL + 'tipi-submission', data)
    .then(resp => {
      return { success: true, content: resp.data };
    })
    .catch(err => {
      if (!err.response)
        return { success: false, code: "418", message: err.message };
      else
        return {
          success: false,
          code: err.response.status,
          message: err.response.status,
        };
    });
}

export async function contactFormSubmission(data) {
  return await axios
    .post(BASE_URL + 'contact-submission', data)
    .then(resp => {
      return { success: true, content: resp.data };
    })
    .catch(err => {
      if (!err.response)
        return { success: false, code: "418", message: err.message };
      else
        return {
          success: false,
          code: err.response.status,
          message: err.response.status,
        };
    });
}
