<template>
  <div id="custom-footer">
    <div class="wrapper">
      <ul>
        <router-link class="router-link" :to="'/accueil/#nos-tipis'"
          >Nos tipis</router-link
        >
        <div class="spacer"></div>
        <router-link class="router-link" :to="'/accueil/#nos-realisations'"
          >Nos réalisations</router-link
        >
        <div class="spacer"></div>
        <router-link class="router-link" :to="'/accueil/#notre-equipe'"
          >Notre équipe</router-link
        >
        <div class="spacer"></div>
        <router-link class="router-link" :to="'/accueil/#nous-contacter'"
          >Nous contacter</router-link
        >
      </ul>
      <ul id="socials">
        <a href="https://www.facebook.com/witente.enr" target="_blank">
          <div class="svg-wrapper">
            <svg-social :name="'facebook'" />
          </div>
        </a>
        <a href="https://www.instagram.com/tipiwitente" target="_blank">
          <div class="svg-wrapper">
            <svg-social :name="'instagram'" />
          </div>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgSocial from "./SvgSocial.vue";

export default {
  name: "CustomFooter",
  components: {
    "svg-social": SvgSocial,
  },
};
</script>
<style>
#custom-footer {
  background-color: var(--main);
  color: var(--background);
}

#custom-footer .wrapper {
  padding: 40px;
}

#custom-footer a {
  text-decoration: none;
  color: var(--background);
}

#custom-footer ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#custom-footer svg {
  width: 100%;
  height: 100%;
}

#custom-footer .svg-wrapper {
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--background);
  opacity: 0.6;
  transition: opacity 250ms ease-out;
}
#custom-footer .svg-wrapper:hover {
  opacity: 1;
}

#custom-footer .spacer {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--background);
}

@media screen and (max-width: 767px) {
  #custom-footer .wrapper {
    padding: 40px 0;
  }
}

@media screen and (max-width: 600px) {
  #custom-footer .wrapper {
    padding: 40px 0;
  }
  #custom-footer ul { 
    flex-direction: column;
  }
  #custom-footer ul#socials { 
    flex-direction: row;
    margin-top: 20px;
  }
}
</style>
