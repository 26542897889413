<template>
  <section id="team" class="wrapper">
    <div>
      <div class="grid grid-2">
        <div class="item" :class="{ active: (displayedEmployee == item.id) }" v-for="item in equipe"
          :key="item.name + '2'" v-on:click="changeDisplayedEmployee(item.id)">
          <div class="image-wrapper"><img :src="'/img/' + item.img" /></div>
          <h2>{{ item.name }}</h2>
        </div>
      </div>
      <div class="screen">
        <div v-for="item in equipe" :key="item.name + '3'" :class="{ hidden: (displayedEmployee !== item.id) }">
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TeamDisplay',
  data() {
    return {
      displayedEmployeeBuffer: 1,
    }
  },
  computed: {
    displayedEmployee() {
      return this.displayedEmployeeBuffer;
    },
    equipe() {
      return [{
        id: 1,
        img: "icha.jpg",
        name: "Richard Legault",
        text: this.$t('equipe.rich')
      },
      {
        id: 2,
        name: "Isis Gaia",
        img: "isis.jpg",
        text: this.$t('equipe.isa')
      },
      {
        id: 3,
        name: "Youka Parenteau",
        img: "youka.jpg",
        text: this.$t('equipe.belle_femme')
      }]
    }
  },
  methods: {
    changeDisplayedEmployee(id) {
      this.displayedEmployeeBuffer = id;
    }
  },
}
</script>

<style>
#team {
  display: flex;
  flex-direction: column;
  gap: 0;
}

#team .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  padding: 0 50px;
}

#team .screen {
  position: relative;
  height: 200px;
  width: 100%;
  z-index: 19;
  margin-top: 40px;
  background-color: var(--background);
  border-radius: 15px;
}

#team .screen>div {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
}

#team .screen .hidden {
  opacity: 0;
}

#team .grid .item {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

#team .grid .item:hover {
  cursor: pointer;
}

#team .grid .item.active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}


#team .grid-2 .item.active h2 {
  background-color: var(--main);
  color: var(--background);
}

#team .grid-2 .item {
  z-index: 20;
  border-radius: 15px;
  overflow: hidden;
}

#team .grid .item h2 {
  background-color: var(--background);
  color: var(--main);
  width: 100%;
  text-align: center;
  z-index: 20;
  padding: 10px 0;
}

#team .grid .item .image-wrapper {
  width: 100%;
  padding-bottom: 100%;
  position: relative;

}

#team .grid .item .image-wrapper img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  #team .screen {
    height: 250px;
  }

  #team .grid {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  #team .screen {
    height: 250px;
    overflow-y: scroll;
  }

  #team .screen p {
    padding-bottom: 40px;
  }

  #team .screen::-webkit-scrollbar {
    display: none;
  }

  #team .grid .item h2 {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  #team .grid .item {
    display: grid;
    grid-template-columns: 0.25fr 1fr;
  }

  #team .grid .item h2 {
    line-height: 100px;
  }

  #team .grid {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  #team .grid .item h2 {
    line-height: 75px;
  }
}

@media screen and (max-width: 500px) {
  #team .grid .item h2 {
    line-height: 60px;
  }
}

@media screen and (max-width: 400px) {
  #team .grid .item h2 {
    line-height: 50px;
  }
}
</style>
