<template>
  <header>
    <div class="wrapper">
      <router-link class="logo-link" :to="'/accueil'"
        ><svg-element class="logo" :name="'logo'"></svg-element
      ></router-link>
      <div v-if="showLinks" id="menu" :class="{ active: active }">
        <ul>
          <router-link class="router-link" :to="'/accueil/#nos-tipis'">{{
            $t("links.tipi")
          }}</router-link>
          <router-link class="router-link" :to="'/accueil/#nos-realisations'">{{
            $t("links.realisations")
          }}</router-link>
          <router-link class="router-link" :to="'/accueil/#notre-equipe'">{{
            $t("links.equipe")
          }}</router-link>
          <router-link class="router-link" :to="'/accueil/#nous-contacter'">{{
            $t("links.contact")
          }}</router-link>
        </ul>
        <hr />
        <ul>
          <!--- 
                    <router-link class="router-link" :to="'/store'">Boutique</router-link>
                    <router-link class="router-link" :to="'/aile-du-vent'">L'aile du vent</router-link>-->
          <change-lang />
          <a :href="pricePdfUrl" target="_blank">
            {{ $t("links.liste") }}
          </a>
          <router-link class="" :to="'/soumission'"
            ><div class="confirm-btn">
              {{ $t("links.soumission") }}
            </div></router-link
          >
        </ul>
      </div>
      <svg
        viewBox="0 0 30 30"
        class="burger"
        :class="{ active: active }"
        @click="$emit('switchState')"
      >
        <rect style="fill: var(--main)" x="0" y="14" width="30" height="2" />
        <rect style="fill: var(--main)" y="0" x="14" height="30" width="2" />
      </svg>
    </div>
  </header>
</template>

<script>
import SvgElementVue from "./SvgElement.vue";
import ChangeLang from "./ChangeLang.vue";
import { useI18n } from "vue3-i18n";
export default {
  name: "NavBar",
  components: {
    "svg-element": SvgElementVue,
    "change-lang": ChangeLang,
  },
  props: ["active", "showLinks"],
  data() {
    return {
      i18n: null,
    };
  },
  mounted() {
    this.i18n = useI18n();
  },
  computed: {
    pricePdfUrl() {
      return "/price/" + this.i18n.getLocale() + ".pdf";
    },
  },
};
</script>

<style>
header {
  height: var(--header-height);
  position: fixed;
  width: 100%;
  z-index: 101;
  top: 0;
  -webkit-box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.4);
  background-color: var(--background-header);
}

header a {
  color: var(--text);
}

header hr {
  display: none;
}

header #menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-left: 50px;
}

header #menu ul {
  display: flex;
  gap: 30px;
  height: 100%;
}

#app.dark header .logo {
  display: none;
}
header .logo-link {
  height: 85%;
}
header #menu ul a {
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  transition: all 250ms linear;
  font-size: 18px;
}

header .wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

header .logo {
  height: 100%;
  max-width: 200px;
}

header #menu ul .router-link:hover {
  cursor: pointer;
  color: var(--main);
  border-top: 4px solid var(--main);
}

header #menu .soumission {
  height: 100%;
  display: flex;
  align-items: center;
}

header #menu .soumission a {
  height: fit-content;
  padding: 10px 20px;
  border: var(--main) 2px solid;
  border-radius: 10px;
  transition: all 500ms linear;
  background: white;
}

header #menu .soumission a:hover {
  border: var(--main) 2px solid;
  background-color: var(--main);
  color: white;
}

header .burger {
  position: fixed;
  top: 30px;
  right: 20px;
  height: 25px;
  width: 25px;
  display: none;
  transition: transform 250ms ease-out;
  transform: rotate(0);
}

header .burger.active {
  transform: rotate(225deg);
}

@media screen and (max-width: 1200px) {
  header #menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background-color: var(--background);
    margin: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    transition: transform 250ms ease-out;
    transform: translateX(100%);
  }

  header .burger {
    display: block;
  }

  header #menu.active {
    transform: translateX(0);
  }

  header hr {
    height: 2px;
    margin: 40px auto;
    width: 200px;
    background-color: var(--text);
    display: block;
  }

  header #menu ul {
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }

  header #menu ul a {
    height: fit-content;
    font-size: 32px;
  }
}
</style>
