<template>
    <div id="change-lang" @click="changeLang()">
        <!---
        <ul>
            <li @click="changeLang('en')">{{ $t("test") }}</li>
            <li @click="changeLang('en')">{{ $t("test") }}</li>
        </ul>-->
        <svg viewBox="0 0 44.446617 31.360603">
            <g transform="translate(-84.228312,-96.448728)">
                <circle
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    id="path739" cx="97.920502" cy="114.04536" r="13.229166" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 97.779672,100.90155 c 0,0 15.584808,12.11109 0.09388,26.56929" id="path1272" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 97.970646,100.68427 c 0,0 -15.127332,12.11109 -0.09113,26.56928" id="path1272-2" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 97.685787,100.80766 0.375538,26.38152" id="path1296" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 88.954537,103.99973 c 0,0 8.73125,4.78811 17.274733,-0.28165" id="path1298" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 89.415184,124.29145 c 0,0 8.731247,-4.9369 17.274736,0.2904" id="path1298-9" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 85.480815,109.6328 c 0,0 13.988776,4.41257 24.879365,-0.18777" id="path1322" />
                <path
                    style="fill-opacity:0;stroke:var(--main);stroke-width:0.926042;stroke-dasharray:none;stroke-opacity:1"
                    d="m 85.407589,118.71423 c 0,0 13.988777,-4.5307 24.879371,0.19279" id="path1322-0" />
                <path id="rect2850"
                    style="fill:var(--background);fill-opacity:1;stroke:var(--main);stroke-width:0.926039;stroke-dasharray:none;stroke-opacity:1"
                    d="m 100.62385,96.911754 c -0.711344,0 -1.284169,0.48497 -1.284169,1.087477 l -0.1816,9.112869 -0.181496,0.56601 -2.179072,6.9211 6.718637,-2.46077 h 23.41184 c 0.71134,0 1.28391,-0.48519 1.28391,-1.0877 V 97.999231 c 0,-0.602507 -0.57257,-1.087477 -1.28391,-1.087477 z" />
                <text
                    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:14.1111px;font-family:'Dosis', serif;font-variant: small-caps;fill:var(--main);stroke-width:0.264583"
                    x="113.79738" y="109.29032">
                    <tspan x="113.79738" y="109.29032"
                        style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:14.1111px;font-family:'Dosis', serif;font-variant: small-caps;text-align:center;text-anchor:middle;fill:var(--main);stroke-width:0.264583">
                        {{ next }}
                    </tspan>
                </text>
            </g>
        </svg>
    </div>
</template>
<script>
import { useI18n } from "vue3-i18n";
export default {
    name: 'ChangeLang',
    data() {
        return {
            i18n: null,
            current: "fr",
        }
    },
    mounted() {
        this.i18n = useI18n()
        this.i18n.setLocale(this.current)
    },
    methods: {
        changeLang() {
            if (this.current === "fr") {
                this.i18n.setLocale("en")
                this.current = "en"
            } else {
                this.i18n.setLocale("fr")
                this.current = "fr"
            }
        }
    },
    computed: {
        next() {
            let x = "En";
            if (this.current === "en") {
                x = "Fr"
            }
            return x
        }
    }

}
</script>
<style>
#change-lang {
    height: 60%;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    transition: all 250ms ease-out;
}

#change-lang:hover {
    cursor: pointer;
    background-color: var(--main)
}

#change-lang:hover circle,
#change-lang:hover path {
    stroke: var(--background) !important;
    transition: all 250ms ease-out;
}

#change-lang:hover path#rect2850 {
    stroke: var(--main) !important;
}

#change-lang svg {
    height: 100%;
    width: auto;
}

@media screen and (max-width: 1200px) {
  #change-lang {
    height: 75px;
  }
}
</style>